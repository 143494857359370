
@tailwind base;
@tailwind components;
@tailwind utilities;

// FONTS
@import "custom/fonts";

// general
@import "custom/vars";
@import "custom/general";

// components
@import "components/buttons";
@import "components/breadcrumb";
@import "components/popup-decision";

// structure
@import "custom/structure/topnav";
@import "custom/structure/footer";

// pages
@import "custom/pages/game";
@import "custom/pages/home";
@import "custom/pages/profile";
@import "custom/pages/collection";
@import "custom/pages/helper";
//@import "custom/pages/hero";
//@import "custom/pages/countdown";
//@import "custom/pages/portfolio";
//@import "custom/pages/contact";

// Plugins
@import "custom/plugins/swiper-slider";
@import "custom/plugins/testi";
@import "custom/plugins/choices";
@import "custom/plugins/datepicker";
@import "custom/plugins/share";
@import "custom/plugins/nouislider";


