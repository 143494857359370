.recent-review {
  @apply relative overflow-hidden bg-white dark:bg-cod-gray-900 rounded-lg p-3 shadow
  hover:shadow-md dark:shadow-cod-gray-800 dark:hover:shadow-cod-gray-800 hover:scale-105 ease-in-out duration-500;

  &__image {
    width: 100px;
    float: left;
    margin-right: 10px;
  }
}

.collection {
  @apply relative overflow-hidden bg-gray-50 dark:bg-cod-gray-900 rounded-md shadow
  hover:shadow-md dark:shadow-cod-gray-800 dark:hover:shadow-cod-gray-800 ease-in-out duration-500 hover:-mt-2 h-fit;
  &__image {
    @apply
    h-[88px]
    min-w-[66px]
    rounded-md
    object-cover;
    //max-w-[140px]
  }
}

.list-wrap {
  @apply p-4
  relative overflow-hidden

  rounded-md shadow
  ease-in-out duration-500
  text-xl   leading-snug font-semibold

  dark:shadow-cod-gray-800
  dark:hover:shadow-cod-gray-800
  hover:shadow-md
  hover:-mt-2;

  &__btn {
    @apply btn
    mr-2 mt-2
    rounded-full
    bg-amber-500
    border-amber-500
    clear-both
    hover:bg-amber-600
    hover:border-amber-600  ;
  }
}

.hexagon {
  margin-top: 30px;
  width: 104px;
  height: 60px;
  background-color: $yellow-st;
  border-color:  $yellow-st;
  position: relative;
  display: inline-block;

  &:before {
    content: " ";
    width: 0;
    height: 0;
    border-bottom: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
    position: absolute;
    top: -30px;
  }

  &:after {
    content: "";
    width: 0;
    position: absolute;
    bottom: -30px;
    border-top: 30px solid;
    border-color: inherit;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
  }
  &:hover{
    transform: rotate(45deg);
    transition: all ease-in-out 500ms;
  }
}

.event{
  @apply relative overflow-hidden flex bg-gray-50 dark:bg-cod-gray-900 rounded-md shadow
  hover:shadow-md dark:shadow-cod-gray-800 dark:hover:shadow-cod-gray-800 ease-in-out duration-500 hover:-mt-2 h-full
;
  &__image {
    @apply aspect-video md:h-[190px] h-auto mb-2 w-full ;
  }
  &__info{
    @apply flex flex-wrap;
    height: calc(100% - 200px);
  }

}