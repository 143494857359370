.share {
  position: absolute;
  bottom: 55px;
  right: 0;
  display: none;
  transition: display 0.4s;

  &__wrap {
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 0;
    z-index: 9999;
  }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      width: 180px;
      margin: 0;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.95);
      backdrop-filter: blur(20px);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
      transition: transform 0.2s;
      transform: translateY(20px);
    }
      &__item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 12px;
        border-bottom: 1px solid rgba(216, 216, 216, 0.2);
        text-decoration: none;
        cursor: pointer;
        transition: background 0.2s;
        overflow: hidden;

        &_icon {
          position: relative;
          display: block;
          flex: 0 0 auto;
          width: 22px;
          height: 22px;
          margin-right: 12px;



        }

        &_text {
          display: block;
          flex: 1 1 auto;
          font-weight: 600;
          text-align: left;
          text-decoration: none;
        }

        &_input {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 1px;
          height: 1px;
          opacity: 0;
          pointer-events: none;
        }

        &:first-child {
          border-radius: 12px 12px 0 0;
        }

        &:last-child {
          border-radius: 0 0 12px 12px;
          border-bottom: 0;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      &.m_from_top {
        & ~ .share {
          transform: translateY(-20px);
        }
      }



  &.m_visible {
    display: block;
  }
}

.btn__share {
  display: block;
  width: 24px;
  height: 24px;

}