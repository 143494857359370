.collection{
  &__description{
   @apply    my-3
   dark:text-silver-500
   text-silver-700
   ;
  }
  &__count{

  }
  &__image-block{
    @apply    mb-3
      block
    w-full
    min-h-[40px]
      rounded-md
    ;
  }
  &__image--large{

  }
}