@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

#popupDecision {
  @apply w-full h-1/6 rounded-t-[10px] hidden dark:bg-cod-gray-700 fixed bottom-[-1px] p-5 z-10 bg-white dark:text-white;
  animation: slideInFromBottom 0.5s ease-in-out;

}
.popup-open{
  @apply overflow-hidden;
  &:before{
    @apply  top-0 left-0 w-full h-full content-[''] opacity-40 bg-black fixed z-1
  }
}
body.popup-open #popupDecision {
  @apply block;
}
