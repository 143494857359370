/*********************************/
/*           Choices             */
/*===============================*/
.filter-search-form {
    .icons {
        @apply absolute top-[48%] -translate-y-1/2 left-3 z-1 text-violet-600 text-[20px];
    }
}
.registration-form {
    @apply relative;
    .submit-btn {
        @apply rounded-lg;
    }
    .form-input,
    .choices[data-type*=select-one] .choices__inner {
        @apply rounded-lg;
    }
}

@media (min-width:992px) {
    .filter-border {
        @apply before:content-[''] before:absolute before:top-[10px] before:right-0 before:h-10 before:z-1 before:border-r before:border-inherit before:rounded-md before:outline-0 dark:before:border-gray-700;
    }
    .registration-form {
        @apply relative;
        .submit-btn {
            @apply rounded-t-none rounded-r-sm rounded-b-sm rounded-l-none;
        }
        .form-input {
            @apply rounded-t-sm rounded-r-none rounded-b-none rounded-l-sm outline-0;
        }
        .choices[data-type*=select-one] .choices__inner {
            @apply rounded-none;
        }
    }
}

.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
    @apply shadow-none border-0 rounded-none text-[15px] min-h-[40px] pt-[8px] pr-[6px]   ;
}

.choices__inner {
    @apply bg-gray-50 dark:bg-cod-gray-800;
}

.choices__list--dropdown,
.choices[data-type*=select-one] .choices__input {
    @apply bg-white dark:bg-cod-gray-900 dark:text-white border-0 rounded-[5px] shadow dark:shadow-gray-800 z-999;
}

.choices__list+ .choices__input{
    @apply bg-white dark:bg-cod-gray-700 border-0  dark:text-white   z-999 w-full h-[30px];
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
    @apply bg-gray-50 dark:bg-cod-gray-800 text-black dark:text-white;
}
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted{
    @apply bg-gray-50 dark:bg-cod-gray-700 text-black dark:text-white;
}
.choices__list,
.choices__list--dropdown .choices__item--selectable {
    @apply text-silver-700 dark:bg-cod-gray-800;
}

.choices__list--dropdown, 
.choices__list[aria-expanded] {
    @apply border-0 z-2;
}

.choices__list--multiple .choices__item,
.choices__list--multiple .choices__item.is-highlighted{
    @apply bg-amber-500 border-amber-500
}
.choices__list--multiple + .choices__input{
    @apply min-w-[0px] #{!important};
    @apply w-[0px] #{!important};
    @apply p-0;


}
.choices[data-type*=select-one]::after{
    @apply  border-transparent border-t-silver-500
}
.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button{
    @apply border-0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    padding-right: 4px;
    //background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}

.choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable{
    @apply pr-[10px] ;
}
.dark .choices[data-type*=select-one] .choices__button{
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}