.profile{
  &__image-block{
    @apply relative h-28 w-28 mx-auto rounded-full shadow dark:shadow-gray-800 ring-4 ring-cod-gray-200
    dark:ring-cod-gray-800 overflow-hidden;
  }
  &__label{
    @apply absolute
    bottom-[-6px]
      right-[calc(50%-25px)]
    mx-auto px-3
    rounded-full
    ring-4 ring-cod-gray-200
    bg-amber-500
    overflow-hidden
    text-sm font-bold
    dark:shadow-gray-800
    dark:ring-cod-gray-800   ;
  }
  &__image{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__info-link{
    @apply w-1/2
    text-center
    my-2  ;

  }

}

a.profile__info-link{
  @apply   underline
  hover:text-amber-500
}

.loader {
  display: block;
  position: relative;
  top: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  z-index: 9999;
}

.loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 2px solid $white-st;
  border-top-color: $yellow-st;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {transform: rotate(360deg);}
}

* , .dark{
  button.tab-link {
    &[aria-selected="true"],
    &:hover {
      color: $yellow-st !important;
    }
  }
}