@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
/*
    custom variable
*/
/* // _general.scss */
/*********************************/
/*             General            */
/*===============================*/
p {
  @apply leading-relaxed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  @apply leading-snug;
}

::selection {
  @apply bg-yellow-600/90 text-white;
}

.body {
  @apply text-base
    text-black
    dark:text-white
    dark:bg-cod-gray-950;
  font-family: "Inter", sans-serif;
  font-display: swap;
}

.bg-amber-500,
*[class^=bg-amber-] {
  color: #000;
}

.more-btn {
  @apply w-1/4 mt-8  mx-auto
   btn rounded-full
   hidden
   cursor-pointer
   border border-amber-500
   dark:hover:bg-cod-gray-900   dark:border-cod-gray-700
   hover:bg-amber-600
   justify-center;
}

.read-more {
  display: none;
}
.read-more {
  @apply md:w-1/4 mb-4 mt-2 py-1  mx-auto
    rounded-full
    justify-center
    md:text-[14px]
    dark:border-cod-gray-700
    dark:hover:bg-cod-gray-800
    cursor-pointer
    z-10
    relative;
}

[data-link] {
  cursor: pointer;
}

.static-page p {
  @apply mb-4;
}
.static-page h3 {
  @apply text-[20px]
      font-bold
    mb-2;
}
.static-page h4 {
  @apply text-[18px]
      font-bold
    mb-2;
}
.static-page a {
  @apply text-amber-500;
}
.static-page ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.not-found-block {
  position: relative;
}
.not-found-image {
  display: block;
  object-fit: fill;
  height: 300px;
  margin: 50px auto -60px;
}
.not-found-title {
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  color: #F7BF00;
}
.not-found-description {
  font-size: 30px;
  margin-bottom: 50px;
}

.empty-list-message {
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.empty-list-message p {
  color: #F7BF00;
  font-size: 32px;
  margin: 20px 0 10px;
  text-align: center;
  width: 100%;
}

.grid:has(.empty-list-message) {
  @apply lg:grid-cols-1
  md:grid-cols-1
  grid-cols-1;
}

.main-section {
  min-height: calc(100vh - 280px);
}

/********************/
/*     Buttons      */
/*==================*/
/* Note: If you want to add your own colors, you can do so by going to this tailwind.config.js file  */
.btn {
  @apply py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center;
  /*  Button icons */
  /* Button Link */
}
.btn.btn-lg {
  @apply py-2.5 px-6 text-lg;
}
.btn.btn-sm {
  @apply py-[5px] px-4 text-sm;
}
.btn.btn-icon {
  @apply p-0 h-10 w-10 inline-flex items-center text-center justify-center text-base;
}
.btn.btn-icon.btn-lg {
  @apply h-12 w-12;
}
.btn.btn-icon.btn-sm {
  @apply h-8 w-8;
}
.btn.btn-link {
  @apply relative p-0 border-none after:content-[""] after:absolute after:h-px after:w-0 after:right-0 after:bottom-0 after:left-0 after:transition-all after:duration-500;
}
.btn.btn-link:hover {
  @apply after:w-full after:right-auto;
}

/********************/
/*    Breadcrumb    */
/*==================*/
.breadcrumb .breadcrumb-item {
  @apply before:content-[""] after:text-sm after:text-black after:dark:text-white after:pl-[5px] last:after:hidden;
}
.breadcrumb .breadcrumb-item:after {
  content: "󰅂" !important;
  font-family: "Material Design Icons";
}
.breadcrumb.breadcrumb-light .breadcrumb-item {
  @apply after:text-white/50;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
#popupDecision {
  @apply w-full h-1/6 rounded-t-[10px] hidden dark:bg-cod-gray-700 fixed bottom-[-1px] p-5 z-10 bg-white dark:text-white;
  animation: slideInFromBottom 0.5s ease-in-out;
}

.popup-open {
  @apply overflow-hidden;
}
.popup-open:before {
  @apply top-0 left-0 w-full h-full content-[""] opacity-40 bg-black fixed z-1;
}

body.popup-open #popupDecision {
  @apply block;
}

/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  @apply fixed right-0 left-0 top-0 z-999 bg-transparent border-0 transition-all duration-500 ease-in-out;
}
#topnav .logo {
  @apply float-left text-black dark:text-white !important;
}
#topnav .logo .l-dark {
  @apply hidden;
}
#topnav .logo .l-light {
  @apply inline-block;
}
#topnav .logo:focus {
  @apply outline-none;
}
#topnav .has-submenu {
  @apply relative;
}
#topnav .has-submenu.active a {
  @apply dark:text-slate-900;
}
#topnav .has-submenu.active .submenu li.active > a {
  @apply text-amber-500 dark:text-amber-500 !important;
}
#topnav .has-submenu.active .submenu li.active .submenu-arrow {
  @apply border-amber-500 dark:border-amber-500 !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  @apply border-amber-500 dark:border-amber-500;
}
#topnav .has-submenu .submenu .submenu-arrow {
  border-width: 0px 0.125rem 0.125rem 0px;
  @apply absolute right-[1.25rem] top-[15px] border-black dark:border-white rounded-[0.5px] inline-block p-[2px] -rotate-[45deg];
}
#topnav .has-submenu .submenu .has-submenu:hover {
  /* .submenu-arrow {
    @apply border-amber-500 dark:border-amber-500;
  } */
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-amber-500 dark:border-amber-500;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  @apply border-amber-500 dark:border-amber-500;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  @apply border-amber-500 dark:border-amber-500;
}
#topnav .navbar-toggle {
  @apply border-0 relative p-0 m-0 cursor-pointer;
}
#topnav .navbar-toggle .lines {
  @apply w-[25px] block relative h-[18px] mt-[30px] mr-0 mb-[26px] ml-[10px];
}
#topnav .navbar-toggle span {
  transition: transform 0.5s ease;
  @apply h-[2px] w-full bg-black dark:bg-white block mb-[5px];
}
#topnav .navbar-toggle span:last-child {
  @apply mb-0;
}
#topnav .navbar-toggle.open span {
  @apply absolute;
}
#topnav .navbar-toggle.open span:first-child {
  @apply top-[6px] rotate-[45deg];
}
#topnav .navbar-toggle.open span:nth-child(2) {
  @apply invisible;
}
#topnav .navbar-toggle.open span:last-child {
  @apply w-full top-[6px] -rotate-[45deg];
}
#topnav .navbar-toggle.open span:hover {
  @apply bg-white;
}
#topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover {
  @apply bg-transparent;
}
#topnav .buy-button {
  @apply float-right leading-[74px];
}
#topnav .buy-button > li {
  @apply leading-[initial];
}
#topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
  @apply hidden;
}
#topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
  @apply inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  @apply relative;
}
#topnav .buy-button .search-bar .searchform {
  @apply after:absolute after:right-[14px] after:top-[14px] after:text-xl after:leading-5 after:pointer-events-none;
}
#topnav .buy-button .search-bar .searchform input[type=text] {
  @apply shadow-none py-[10px] pr-[42px] pl-3 h-11 text-sm block outline-none  !important;
}
#topnav .buy-button .search-bar .searchform input[type=submit] {
  @apply hidden;
}
#topnav .buy-button .search-bar .searchform:after {
  content: "󰍉";
  font-family: "Material Design Icons";
}
#topnav .buy-button .dropdown .dropdown-toggle,
#topnav .buy-menu-btn .dropdown .dropdown-toggle {
  @apply after:hidden;
}
#topnav .navigation-menu {
  @apply list-none m-0 p-0;
}
#topnav .navigation-menu > li {
  @apply float-left block relative my-0 mx-[10px];
}
#topnav .navigation-menu > li > a {
  @apply flex   text-[15px]   font-semibold tracking-[1px] leading-6   !important;
}
#topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
  @apply text-amber-500;
}
#topnav .navigation-menu > li > a.btn:hover {
  @apply text-black !important;
}
#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
  @apply text-amber-500;
}
#topnav .navigation-menu > li:hover > a.btn, #topnav .navigation-menu > li.active > a.btn {
  @apply text-black !important;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  @apply py-[10px] px-5 whitespace-nowrap text-xs tracking-[0.04em] font-semibold text-slate-400 !important;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border-width: 0 2px 2px 0;
  @apply border-black dark:border-white rounded-[0.5px] inline-block p-[2px] rotate-[45deg] absolute transition-all duration-500 -right-px top-[33px];
}
#topnav .menu-extras {
  @apply float-right;
}
#topnav.scroll {
  @apply bg-white dark:bg-cod-gray-900 border-none shadow;
}
#topnav.scroll .navigation-menu > li > a {
  @apply text-black dark:text-white;
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  @apply border-black dark:border-white;
}
#topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
  @apply text-amber-500;
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
  @apply border-amber-500 dark:border-amber-500;
}
#topnav.defaultscroll.dark-menubar .logo {
  @apply leading-[70px];
}
#topnav.defaultscroll.scroll .logo {
  @apply leading-[62px];
}
#topnav.nav-sticky {
  @apply bg-white dark:bg-cod-gray-800 shadow dark:shadow-cod-gray-700;
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  @apply text-black dark:text-white !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-amber-500 dark:text-amber-500 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  @apply border-amber-500 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  @apply text-amber-500 dark:text-amber-500 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  @apply border-black dark:border-white;
}
#topnav.nav-sticky.tagline-height {
  @apply top-0 !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  @apply inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  @apply hidden;
}
#topnav.nav-sticky .logo .l-dark {
  @apply inline-block;
}
#topnav.nav-sticky .logo .l-light {
  @apply hidden;
}

.logo {
  @apply font-semibold text-[24px] mr-[15px] pt-0 px-[6px] pb-0 tracking-[1px] leading-[68px];
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply w-[1120px] !important;
  }
}
@media (min-width: 1024px) {
  #topnav .navigation-menu {
    @apply flex flex-wrap justify-center;
  }
  #topnav .navigation-menu > li .submenu {
    transition: all 0.2s ease;
    @apply absolute top-full left-0 z-[1000] py-[15px] px-0 list-none min-w-[180px] invisible opacity-0 mt-[10px] rounded-md bg-white dark:bg-cod-gray-800 shadow dark:shadow-cod-gray-700;
  }
  #topnav .navigation-menu > li .submenu li {
    @apply relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block py-[10px] px-5 clear-both whitespace-nowrap text-[14px] tracking-[0.04em] font-semibold text-black dark:text-white leading-[17px];
  }
  #topnav .navigation-menu > li .submenu li ul {
    @apply list-none pl-0 m-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    @apply whitespace-nowrap left-1/2 -translate-x-1/2 fixed top-auto flex;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    @apply overflow-hidden align-top w-1/5;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    @apply left-full top-0 ml-[10px] -mt-px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    @apply left-[101%] top-0 ml-[10px] -mt-px;
  }
  #topnav .navigation-menu > li > a {
    @apply py-[25px] min-h-[62px];
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    @apply border-amber-500 dark:border-amber-500;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    @apply left-auto right-0 before:left-auto before:right-[10px];
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    @apply left-auto right-full ml-0 mr-[10px];
  }
  #topnav .navigation-menu.nav-light > li > a {
    @apply text-white/50 !important;
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    @apply border-white !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    @apply text-white !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    @apply border-white/50;
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    @apply border-white !important;
  }
  #topnav .buy-button {
    @apply pl-[15px];
  }
  #topnav .navbar-toggle {
    @apply hidden;
  }
  #topnav #navigation {
    @apply block !important;
  }
  #topnav.scroll {
    @apply top-0;
  }
  #topnav.scroll .navigation-menu > li > a {
    @apply py-5;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    @apply py-[25px];
  }
}
@media (max-width: 1023px) {
  #topnav {
    @apply bg-white dark:bg-cod-gray-800 shadow dark:shadow-gray-700 min-h-[74px];
  }
  #topnav .logo .l-dark {
    @apply inline-block !important;
  }
  #topnav .logo .l-light {
    @apply hidden !important;
  }
  #topnav .container {
    @apply w-auto;
  }
  #topnav #navigation {
    @apply max-h-[400px] shadow;
  }
  #topnav .navigation-menu {
    @apply float-none;
  }
  #topnav .navigation-menu > li {
    @apply float-none;
  }
  #topnav .navigation-menu > li .submenu {
    @apply hidden list-none pl-5 m-0;
  }
  #topnav .navigation-menu > li .submenu li a {
    transition: all 0.3s;
    @apply block relative py-[7px] px-[15px] text-[14px] tracking-[0.04em] font-semibold text-black dark:text-white !important;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    @apply py-[7px] px-[15px];
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    @apply list-none pl-0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    @apply block relative py-[10px] px-[15px] text-xs tracking-[2px] text-slate-200;
  }
  #topnav .navigation-menu > li .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    @apply hidden list-none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    @apply block;
  }
  #topnav .navigation-menu > li > a {
    @apply text-black dark:text-white py-[10px] px-[20px] after:absolute after:right-[15px];
  }
  #topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover, #topnav .navigation-menu > li.has-submenu.open > a {
    @apply text-amber-500;
  }
  #topnav .menu-extras .menu-item {
    @apply border-gray-200 dark:border-gray-700;
  }
  #topnav .navbar-header {
    @apply float-left;
  }
  #topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
    @apply inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
    @apply hidden;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    @apply rotate-[45deg] absolute right-5 top-3;
  }
  #topnav .has-submenu.active a {
    @apply text-amber-500;
  }

  #navigation {
    @apply absolute top-[74px] left-0 w-full hidden h-auto bg-white dark:bg-cod-gray-800 overflow-auto;
  }
  #navigation.open {
    @apply block overflow-y-auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    @apply right-2 top-4;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 mt-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    @apply visible opacity-100 ml-0 mr-0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu > li:hover > .submenu {
    @apply visible opacity-100 ml-0 mr-0;
  }

  .navbar-toggle {
    @apply block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    @apply block my-0 mx-[10px] py-[10px] px-5 !important;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  @apply absolute w-full z-[99] text-sm py-[13px] px-0;
}

@media screen and (max-width: 575px) {
  .tagline {
    @apply hidden;
  }

  .tagline-height {
    @apply top-0 !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    @apply top-[47px] !important;
  }
}
.sidebar-nav > .navbar-item:hover .navbar-link, .sidebar-nav > .navbar-item.active .navbar-link {
  @apply text-amber-500;
}
.sidebar-nav li.active a {
  @apply text-amber-500;
}
.sidebar-nav li.account-menu.active .navbar-link, .sidebar-nav li.account-menu:hover .navbar-link {
  @apply text-amber-500 dark:text-white !important;
}

/* // _footer.scss */
/*********************************/
/*         Footer                */
/*===============================*/
.footer .foot-subscribe input::placeholder {
  @apply text-gray-200;
}

.game__ttb {
  @apply p-4
    w-full
    rounded-lg
    bg-cod-gray-100
    dark:bg-cod-gray-900
    dark:shadow-cod-gray-700;
}
.game__ttb-item {
  @apply flex
      mb-2
      items-center
      justify-between
      w-full;
}
.game__ttb-text {
  @apply flex
      items-center;
}
.game__ttb-text img {
  margin-right: 10px;
  filter: invert(100%) brightness(100%) saturate(0%);
}
.game__ttb-time {
  @apply p-2
      min-w-[100px]
      border
      rounded-l
      border-cod-gray-700
      font-semibold
      text-lg
      text-center
      dark:text-white;
}
.game__ttb-cnt {
  @apply mt-4
        block
        dark:text-silver-500
        text-sm
        text-right;
}
.game__related .games-list__item-link:hover {
  position: relative;
  margin-top: 0;
}
.game__screens--img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 3.5/2;
}
.game__platforms span {
  @apply py-0.5 mr-2 px-2.5
      rounded-full
      text-xs
      font-medium
      bg-cod-gray-100
      text-cod-gray-800
      dark:bg-cod-gray-900
      dark:text-silver-500
      whitespace-nowrap;
}
.game__genres a {
  @apply py-2 mr-2 px-3
      mb-2 inline-block
      rounded-full
      text-sm
      font-medium
      border
      whitespace-nowrap
      dark:border-cod-gray-700
      dark:text-silver-500
      dark:bg-cod-gray-950
      hover:opacity-80;
}
.game__stats-item {
  @apply flex flex-wrap
    items-center
    justify-center
    rounded
    mr-2 p-3 mb-2
    font-bold
    border
    hover:bg-cod-gray-100
    dark:border-cod-gray-700
    dark:text-silver-500
    dark:bg-cod-gray-950
    dark:hover:bg-cod-gray-900;
}
.game__info-item {
  @apply m-2 p-3
    flex
    justify-between
    rounded
    border border-cod-gray-200
    hover:bg-cod-gray-200
    dark:border-cod-gray-700
    dark:hover:bg-cod-gray-800;
}
.game__info-item svg g,
.game__info-item svg path {
  stroke: #161c2d !important;
}
.game__stats-wrap {
  @apply p-4 mt-6
    rounded-lg
    md:flex
    bg-cod-gray-100
    dark:bg-cod-gray-900
    dark:shadow-cod-gray-700;
}
.game__info {
  @apply p-2
      w-full
    absolute bottom-0 left-0;
}
.game__rating {
  @apply bg-white
    w-[24px] h-[24px]
    rounded-full
    z-1
    text-cod-gray-900
    font-bold text-sm
    flex items-center justify-center
    shadow dark:shadow-cod-gray-900;
}
.game__rating + .game__icons {
  @apply pl-4
    left-[-13px];
}
.game__icons {
  @apply px-2
    bg-cod-gray-900
    bg-opacity-80
    relative
    z-[0]
    rounded-full;
}
.game__icons--own {
  @apply rounded-full
      right-0
      bottom-0
      m-2
      absolute
      left-auto;
}
.game__icons img {
  filter: invert(100%) brightness(100%) saturate(0%);
}
.game__review {
  @apply md:flex flex-wrap justify-between;
}
.game__review-item {
  @apply mb-2 p-5

    relative
    overflow-hidden
    rounded-lg
    bg-white dark:bg-cod-gray-900
    shadow dark:shadow-cod-gray-900
    ease-in-out duration-500 cursor-pointer
    md:w-[calc(50%_-_10px)];
}
.game__review-image {
  @apply rounded-full bg-amber-500 h-[60px];
  object-fit: cover;
}
.game__review-rating {
  @apply ml-2
    dark:text-silver-500
    text-silver-700
    font-semibold;
}
.game__review-login {
  @apply text-[18px] font-bold   hover:text-amber-500 w-full;
}
.game__review-likes-block {
  @apply px-3 py-1 h-8
    flex justify-between items-center
    rounded-full
    dark:border-cod-gray-700  border;
  width: max-content;
}
.game__review-likes {
  @apply font-semibold text-[18px] ml-3;
}
.game__review-comment {
  @apply mt-5
    font-normal dark:text-silver-500
    text-silver-700;
}
.game__review-date {
  @apply text-sm font-bold
    mt-5
    dark:text-silver-600
    text-silver-700;
}
.game__review-comment {
  overflow: hidden;
  max-height: 98px;
  transition: max-height 0.3s ease;
}
.game__review-comment.expanded {
  max-height: none;
}
.game__review-comment.expanded + .read-more {
  z-index: 11;
  position: relative;
  display: block;
}
.game__review-comment--full {
  max-height: none;
}

.dark .game__info-item svg g,
.dark .game__info-item svg path, .dark .game__review-likes-block svg g,
.dark .game__review-likes-block svg path {
  stroke: #fff !important;
}

.games-list__item {
  @apply h-full
    h-fit
    md:min-h-[270px]
    relative;
}
.games-list__item-link {
  @apply h-full p-2
    h-fit
    rounded-lg
    block  relative overflow-hidden
    border border-cod-gray-100
    bg-white
    transition
    dark:bg-cod-gray-900
    dark:border-cod-gray-800
    dark:shadow-md
    hover:shadow-md
    hover:-mt-2
    hover:z-10

    hover:absolute
    hover:h-auto;
  transition-duration: 500ms;
}
*:has(.games-list .games-list__item-link,
.games-list .tiny-slide__item-link) {
  position: relative;
}
.games-list__item-image {
  @apply w-full
    rounded-lg
    dark:shadow-cod-gray-700;
  object-fit: cover;
  max-height: 320px;
  aspect-ratio: 3/4;
}
.games-list__item-name {
  @apply mt-[10px]
    block
    font-semibold
    hover:text-amber-500;
}
.games-list__item-info {
  display: none;
}
.games-list__category {
  @apply absolute
    top-3 left-3
    text-xs
    font-bold
    block
    mb-2 px-2 py-1
    text-silver-500
    rounded-full
    bg-opacity-80
    bg-cod-gray-900;
}
.games-list__slider {
  position: relative;
}
.games-list__slider .games-list__item-info {
  @apply bg-white
    dark:bg-cod-gray-900
    absolute
    w-full
    z-10;
  transition: max-height 0.3s ease;
  bottom: 0;
  right: 0;
  padding: 0 10px 10px;
}
.games-list__item-link:hover .games-list__item-info {
  display: block;
}

.support-language {
  @apply text-sm
  w-full;
}
.support-language tr {
  @apply p-2
    border-b
    border-cod-gray-200
    dark:border-cod-gray-700;
}
.support-language th span {
  @apply py-1 px-2
    bg-cod-gray-200
    dark:bg-cod-gray-700
    text-center
    rounded;
}
.support-language td, .support-language th {
  @apply py-3
    text-center;
}

.tiny-slide {
  position: relative;
}
.tiny-slide .games-list__item-image {
  min-height: 270px;
  object-fit: cover;
}

#gameTab .active-tab {
  color: #f7bf00 !important;
  border-color: #f7bf00 !important;
}

.full-img__modal {
  @apply hidden fixed z-[1000] inset-0 overflow-auto bg-cod-gray-900 bg-opacity-80 items-center;
}

.full-img__modal-content {
  @apply mx-auto block w-4/5 max-w-3xl max-h-screen m-auto object-contain;
  max-height: calc(100vh - 100px);
}

.full-img__close {
  @apply absolute top-4 right-8 text-white text-4xl   cursor-pointer;
}

.full-img__close:hover,
.full-img__close:focus {
  @apply text-gray-400;
}

.recent-review {
  @apply relative overflow-hidden bg-white dark:bg-cod-gray-900 rounded-lg p-3 shadow
  hover:shadow-md dark:shadow-cod-gray-800 dark:hover:shadow-cod-gray-800 hover:scale-105 ease-in-out duration-500;
}
.recent-review__image {
  width: 100px;
  float: left;
  margin-right: 10px;
}

.collection {
  @apply relative overflow-hidden bg-gray-50 dark:bg-cod-gray-900 rounded-md shadow
  hover:shadow-md dark:shadow-cod-gray-800 dark:hover:shadow-cod-gray-800 ease-in-out duration-500 hover:-mt-2 h-fit;
}
.collection__image {
  @apply h-[88px]
    min-w-[66px]
    rounded-md
    object-cover;
}

.list-wrap {
  @apply p-4
  relative overflow-hidden

  rounded-md shadow
  ease-in-out duration-500
  text-xl   leading-snug font-semibold

  dark:shadow-cod-gray-800
  dark:hover:shadow-cod-gray-800
  hover:shadow-md
  hover:-mt-2;
}
.list-wrap__btn {
  @apply btn
    mr-2 mt-2
    rounded-full
    bg-amber-500
    border-amber-500
    clear-both
    hover:bg-amber-600
    hover:border-amber-600;
}

.hexagon {
  margin-top: 30px;
  width: 104px;
  height: 60px;
  background-color: #F7BF00;
  border-color: #F7BF00;
  position: relative;
  display: inline-block;
}
.hexagon:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  position: absolute;
  top: -30px;
}
.hexagon:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -30px;
  border-top: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}
.hexagon:hover {
  transform: rotate(45deg);
  transition: all ease-in-out 500ms;
}

.event {
  @apply relative overflow-hidden flex bg-gray-50 dark:bg-cod-gray-900 rounded-md shadow
  hover:shadow-md dark:shadow-cod-gray-800 dark:hover:shadow-cod-gray-800 ease-in-out duration-500 hover:-mt-2 h-full;
}
.event__image {
  @apply aspect-video md:h-[190px] h-auto mb-2 w-full;
}
.event__info {
  @apply flex flex-wrap;
  height: calc(100% - 200px);
}

.profile__image-block {
  @apply relative h-28 w-28 mx-auto rounded-full shadow dark:shadow-gray-800 ring-4 ring-cod-gray-200
    dark:ring-cod-gray-800 overflow-hidden;
}
.profile__label {
  @apply absolute
    bottom-[-6px]
      right-[calc(50%-25px)]
    mx-auto px-3
    rounded-full
    ring-4 ring-cod-gray-200
    bg-amber-500
    overflow-hidden
    text-sm font-bold
    dark:shadow-gray-800
    dark:ring-cod-gray-800;
}
.profile__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.profile__info-link {
  @apply w-1/2
    text-center
    my-2;
}

a.profile__info-link {
  @apply underline
  hover:text-amber-500;
}

.loader {
  display: block;
  position: relative;
  top: 0;
  left: 50%;
  width: 50px;
  height: 50px;
  z-index: 9999;
}

.loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: #F7BF00;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
* button.tab-link[aria-selected=true], * button.tab-link:hover, .dark button.tab-link[aria-selected=true], .dark button.tab-link:hover {
  color: #F7BF00 !important;
}

.collection__description {
  @apply my-3
   dark:text-silver-500
   text-silver-700;
}
.collection__image-block {
  @apply mb-3
      block
    w-full
    min-h-[40px]
      rounded-md;
}
/*********************************/
/*             Helper            */
/*===============================*/
.container {
  @apply relative;
}

/* Cookies */
.cookie-popup-not-accepted {
  @apply block;
  animation: cookie-popup-in 0.5s ease forwards;
}

.cookie-popup-accepted {
  @apply hidden;
}

@keyframes cookie-popup-in {
  from {
    bottom: -6.25rem;
  }
  to {
    bottom: 1.25rem;
  }
}
/* Shapes */
.shape > svg {
  transform: scale(2);
  transform-origin: theme("transformOrigin.top") theme("transformOrigin.center");
}
.shape.marketing-hero {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

/* Preloader */
#preloader {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 99999;
  @apply fixed inset-0;
}
#preloader #status {
  @apply absolute left-0 right-0 top-1/2 -translate-y-1/2;
}
#preloader #status .spinner {
  @apply w-10 h-10 relative my-[100px] mx-auto;
}
#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  @apply w-full h-full rounded-full bg-violet-600/60 absolute top-0 left-0;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
/* Switcher */
.label .ball {
  transition: transform 0.2s linear;
  @apply translate-x-6;
}

.checkbox:checked + .label .ball {
  @apply translate-x-0;
}

.mover {
  animation: mover 1.5s infinite alternate;
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
.wave-effect span {
  animation: wave 1s infinite;
  animation-delay: calc(.1s * var(--a));
}

@keyframes wave {
  0%, 40%, 100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
}
/*****************/
/*  Swiper Slider     */
/*****************/
.swiper-slider-hero .swiper-container,
.swiper-slider-hero .swiper-slide .slide-inner {
  @apply absolute right-0 top-0 w-full h-full;
}
.swiper-slider-hero .swiper-container .swiper-button-prev,
.swiper-slider-hero .swiper-container .swiper-button-next {
  @apply bg-transparent w-[35px] h-[35px] leading-[35px] -mt-[30px] bg-none border border-solid border-white/50;
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before,
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  @apply font-bold text-white;
  font-family: "Material Design Icons";
}
.swiper-slider-hero .swiper-container .swiper-button-prev:hover,
.swiper-slider-hero .swiper-container .swiper-button-next:hover {
  @apply bg-violet-600 border-violet-600;
}
.swiper-slider-hero .swiper-container .swiper-button-prev:before {
  content: "󰅁";
}
.swiper-slider-hero .swiper-container .swiper-button-next:before {
  content: "󰅂";
}
.swiper-slider-hero .swiper-pagination-bullet {
  @apply text-gray-50 bg-transparent;
}
.swiper-slider-hero .swiper-pagination-bullet-active {
  @apply text-white;
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-slider-hero .swiper-pagination-custom,
.swiper-slider-hero .swiper-pagination-fraction {
  @apply bottom-[45px];
}
.swiper-slider-hero .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply my-0 mx-[13px];
}

/*********************************/
/*         Testimonial           */
/*===============================*/
.client-testi {
  @apply cursor-e-resize;
}
.client-testi .content {
  @apply before:content-[""] before:absolute before:top-8 before:left-0 before:ml-3 before:box-border before:border-8 before:border-solid before:origin-[0] before:rotate-[135deg] before:shadow before:border-t-transparent before:border-r-white dark:before:border-r-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-l-transparent;
  /* border-color: transparent theme('colors.white') theme('colors.white') transparent; */
}

.customer-testi {
  @apply cursor-e-resize;
}
.customer-testi .content {
  @apply before:content-[""] before:absolute before:left-1/2 before:-bottom-[4px] before:box-border before:border-8 before:rotate-[45deg] before:border-t-transparent before:border-r-white dark:before:border-r-slate-900 before:border-b-white dark:before:border-b-slate-900 before:border-l-transparent before:shadow-testi dark:before:shadow-gray-700;
}
.customer-testi .content:before {
  transform-origin: 0 0;
}

.tns-nav {
  @apply text-center mt-3;
}
.tns-nav button {
  @apply rounded-[3px] bg-white dark:bg-slate-900 border-4 border-amber-500/20 dark:border-amber-500/40 transition-all duration-500 ease-in-out m-0.5 p-px;
}
.tns-nav button.tns-nav-active {
  @apply border-amber-500;
}

.tns-outer {
  position: relative;
}

.slider-visible .tns-ovh {
  overflow: visible;
}

/* Tns control */
.tns-controls button[data-controls=prev],
.tns-controls button[data-controls=next] {
  @apply absolute top-2/4 -translate-y-2/4 text-base w-8 h-8 rounded-full bg-white dark:bg-cod-gray-900 text-dark dark:text-white border-0 transition-all duration-500 ease-in-out z-10 shadow-md dark:shadow-cod-gray-800;
}
.tns-controls button[data-controls=prev]:hover,
.tns-controls button[data-controls=next]:hover {
  @apply bg-amber-500;
}
.tns-controls button[data-controls=prev] {
  @apply -left-2;
}
.tns-controls button[data-controls=next] {
  @apply -right-2;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-360px * 6));
  }
}
.slider:after {
  transform: rotateZ(360deg);
}
.slider .slide-track {
  animation: scroll 120s linear infinite;
  width: calc(360px * 20);
}

/*********************************/
/*           Choices             */
/*===============================*/
.filter-search-form .icons {
  @apply absolute top-[48%] -translate-y-1/2 left-3 z-1 text-violet-600 text-[20px];
}

.registration-form {
  @apply relative;
}
.registration-form .submit-btn {
  @apply rounded-lg;
}
.registration-form .form-input,
.registration-form .choices[data-type*=select-one] .choices__inner {
  @apply rounded-lg;
}

@media (min-width: 992px) {
  .filter-border {
    @apply before:content-[""] before:absolute before:top-[10px] before:right-0 before:h-10 before:z-1 before:border-r before:border-inherit before:rounded-md before:outline-0 dark:before:border-gray-700;
  }

  .registration-form {
    @apply relative;
  }
  .registration-form .submit-btn {
    @apply rounded-t-none rounded-r-sm rounded-b-sm rounded-l-none;
  }
  .registration-form .form-input {
    @apply rounded-t-sm rounded-r-none rounded-b-none rounded-l-sm outline-0;
  }
  .registration-form .choices[data-type*=select-one] .choices__inner {
    @apply rounded-none;
  }
}
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  @apply shadow-none border-0 rounded-none text-[15px] min-h-[40px] pt-[8px] pr-[6px];
}

.choices__inner {
  @apply bg-gray-50 dark:bg-cod-gray-800;
}

.choices__list--dropdown,
.choices[data-type*=select-one] .choices__input {
  @apply bg-white dark:bg-cod-gray-900 dark:text-white border-0 rounded-[5px] shadow dark:shadow-gray-800 z-999;
}

.choices__list + .choices__input {
  @apply bg-white dark:bg-cod-gray-700 border-0  dark:text-white   z-999 w-full h-[30px];
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  @apply bg-gray-50 dark:bg-cod-gray-800 text-black dark:text-white;
}

.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  @apply bg-gray-50 dark:bg-cod-gray-700 text-black dark:text-white;
}

.choices__list,
.choices__list--dropdown .choices__item--selectable {
  @apply text-silver-700 dark:bg-cod-gray-800;
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  @apply border-0 z-2;
}

.choices__list--multiple .choices__item,
.choices__list--multiple .choices__item.is-highlighted {
  @apply bg-amber-500 border-amber-500;
}

.choices__list--multiple + .choices__input {
  @apply min-w-[0px] !important;
  @apply w-[0px] !important;
  @apply p-0;
}

.choices[data-type*=select-one]::after {
  @apply border-transparent border-t-silver-500;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
  @apply border-0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding-right: 4px;
}

.choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
  @apply pr-[10px];
}

.dark .choices[data-type*=select-one] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}

/*********************************/
/*         Datepicker           */
/*===============================*/
.qs-datepicker-container {
  @apply bg-white dark:bg-slate-900 border-inherit dark:border-gray-800;
}

.qs-datepicker-container .qs-controls {
  @apply bg-gray-50 dark:bg-slate-800;
}

.qs-datepicker-container .qs-controls .qs-month-year {
  @apply font-semibold text-[15px] dark:text-white;
}

.qs-datepicker-container .qs-controls .qs-month-year:hover {
  @apply border-b rounded-md outline-inherit border-inherit;
}

.qs-squares .qs-square {
  @apply text-[15px] h-[34px] w-[34px] text-slate-400;
}

.qs-squares .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover,
.qs-squares .qs-square.qs-current {
  text-decoration: none;
  @apply bg-violet-600 text-white rounded-[30px] font-semibold;
}

.qs-squares .qs-day {
  @apply font-normal text-slate-900 dark:text-white;
}

.share {
  position: absolute;
  bottom: 55px;
  right: 0;
  display: none;
  transition: display 0.4s;
}
.share__wrap {
  position: static;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 0;
  z-index: 9999;
}
.share__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 180px;
  margin: 0;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(20px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;
  transform: translateY(20px);
}
.share__item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  border-bottom: 1px solid rgba(216, 216, 216, 0.2);
  text-decoration: none;
  cursor: pointer;
  transition: background 0.2s;
  overflow: hidden;
}
.share__item_icon {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: 22px;
  height: 22px;
  margin-right: 12px;
}
.share__item_text {
  display: block;
  flex: 1 1 auto;
  font-weight: 600;
  text-align: left;
  text-decoration: none;
}
.share__item_input {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
}
.share__item:first-child {
  border-radius: 12px 12px 0 0;
}
.share__item:last-child {
  border-radius: 0 0 12px 12px;
  border-bottom: 0;
}
.share__item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.share.m_from_top ~ .share {
  transform: translateY(-20px);
}
.share.m_visible {
  display: block;
}

.btn__share {
  display: block;
  width: 24px;
  height: 24px;
}

.noUi-target {
  @apply dark:bg-cod-gray-700 shadow-none dark:border-cod-gray-700 mb-[40px];
}

.noUi-connect {
  @apply bg-amber-500;
}

.noUi-horizontal {
  height: 6px;
}

.noUi-horizontal .noUi-handle {
  width: 26px;
  height: 14px;
  @apply dark:bg-cod-gray-700 shadow-none dark:border-cod-gray-700;
}

.noUi-handle:after, .noUi-handle:before {
  @apply bg-silver-700;
  height: 8px;
  left: 10px;
  top: 2px;
}

.noUi-handle:after {
  left: 13px;
}

.noUi-horizontal .noUi-tooltip {
  @apply dark:bg-cod-gray-700 dark:text-silver-500 top-[120%] bottom-auto text-[14px]  dark:border-cod-gray-700 py-0;
}