.noUi-target{
  @apply dark:bg-cod-gray-700 shadow-none dark:border-cod-gray-700 mb-[40px];
}
.noUi-connect{
  @apply bg-amber-500;
}
.noUi-horizontal{
  height: 6px;
}

.noUi-horizontal .noUi-handle {
  width: 26px;
  height: 14px;
  @apply dark:bg-cod-gray-700 shadow-none dark:border-cod-gray-700;
}
.noUi-handle:after, .noUi-handle:before{
  @apply bg-silver-700  ;
  height: 8px;
  left: 10px;
  top: 2px;
}
.noUi-handle:after{
  left: 13px;
}
.noUi-horizontal .noUi-tooltip{
  @apply dark:bg-cod-gray-700 dark:text-silver-500 top-[120%] bottom-auto text-[14px]  dark:border-cod-gray-700 py-0
}