/* // _general.scss */

/*********************************/
/*             General            */
/*===============================*/
p {
    @apply leading-relaxed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    @apply leading-snug;
}

::selection {
    @apply bg-yellow-600/90 text-white;
}


.body{
    @apply
    text-base
    text-black
    dark:text-white
    dark:bg-cod-gray-950;
    font-family: 'Inter', sans-serif;
    font-display: swap;

}
.bg-amber-500,
*[class^="bg-amber-"]{
    color: $pure-black-st;
}

.more-btn{
   @apply  w-1/4 mt-8  mx-auto
   btn rounded-full
   hidden
   cursor-pointer
   border border-amber-500
   dark:hover:bg-cod-gray-900   dark:border-cod-gray-700
   hover:bg-amber-600
   justify-center ;
}

.read-more {
  & {
    @apply
    md:w-1/4 mb-4 mt-2 py-1  mx-auto
    rounded-full
    justify-center
    md:text-[14px]
    dark:border-cod-gray-700
    dark:hover:bg-cod-gray-800
    cursor-pointer
    z-10
    relative
  }
  display: none;
}

[data-link]{
  cursor: pointer;
}

.static-page{
  p{
    @apply  mb-4;
  }
  h3{
    @apply text-[20px]
      font-bold
    mb-2
  }
  h4{
    @apply text-[18px]
      font-bold
    mb-2
  }
  a{
  @apply text-amber-500
  }
  ul{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}

.not-found{
  &-block{
    position: relative;
  }

  &-image {
    display: block;
    object-fit: fill;
    height: 300px;
    margin : 50px auto -60px;
  }

  &-title {
    font-size: 100px;
    font-weight: bold;
    text-align: center;
    color: $yellow-st;
  }

  &-description {
    font-size: 30px;
    margin-bottom: 50px;
  }
}

.empty-list-message{
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  p{
    color: $yellow-st;
    font-size: 32px;
    margin: 20px 0 10px;
    text-align: center;

    width: 100%;
  }
}
.grid:has(.empty-list-message) {
  @apply lg:grid-cols-1
  md:grid-cols-1
  grid-cols-1
}

.main-section{
  min-height: calc(100vh - 280px);
}
