.game{

  &__ttb{
    @apply
    p-4
    w-full
    rounded-lg
    bg-cod-gray-100
    dark:bg-cod-gray-900
    dark:shadow-cod-gray-700;;

    &-item{
      @apply flex
      mb-2
      items-center
      justify-between
      w-full;
    }

    &-text{
      @apply flex
      items-center;
      img{
        margin-right: 10px;
        filter: invert(100%) brightness(100%) saturate(0%);
      }

    }
    &-time{
      @apply p-2
      min-w-[100px]
      border
      rounded-l
      border-cod-gray-700
      font-semibold
      text-lg
      text-center
      dark:text-white;
    }
    &-cnt{
      @apply
        mt-4
        block
        dark:text-silver-500
        text-sm
        text-right
    }
  }

  &__related{
    .games-list__item-link:hover{
      position: relative;
      margin-top: 0;
    }
  }

  &__screens {
    &--img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 3.5/2;
    }
  }

  &__platforms {
    span {
      @apply py-0.5 mr-2 px-2.5
      rounded-full
      text-xs
      font-medium
      bg-cod-gray-100
      text-cod-gray-800
      dark:bg-cod-gray-900
      dark:text-silver-500
      whitespace-nowrap
    }
  }

  &__genres {
    a {
      @apply py-2 mr-2 px-3
      mb-2 inline-block
      rounded-full
      text-sm
      font-medium
      border
      whitespace-nowrap
      dark:border-cod-gray-700
      dark:text-silver-500
      dark:bg-cod-gray-950
      hover:opacity-80;
    }
  }




  &__stats-item {
    @apply
    flex flex-wrap
    items-center
    justify-center
    rounded
    mr-2 p-3 mb-2
    font-bold
    border
    hover:bg-cod-gray-100
    dark:border-cod-gray-700
    dark:text-silver-500
    dark:bg-cod-gray-950
    dark:hover:bg-cod-gray-900;
  }

  &__info-item {
    @apply
    m-2 p-3
    flex
    justify-between
    rounded
    border border-cod-gray-200
    hover:bg-cod-gray-200
    dark:border-cod-gray-700
    dark:hover:bg-cod-gray-800;
    svg g,
    svg path {
      stroke: rgb(22, 28, 45) !important;
    }
  }

  &__stats-wrap {
    @apply p-4 mt-6
    rounded-lg
    md:flex
    bg-cod-gray-100
    dark:bg-cod-gray-900
    dark:shadow-cod-gray-700;
  }

  &__info{
    @apply p-2
      w-full
    absolute bottom-0 left-0;
  }
  &__rating{
    @apply bg-white
    w-[24px] h-[24px]
    rounded-full
    z-1
    text-cod-gray-900
    font-bold text-sm
    flex items-center justify-center
    shadow dark:shadow-cod-gray-900;
  }
  &__rating +  &__icons{
    @apply pl-4
    left-[-13px];
  }

  &__icons{
    @apply px-2
    bg-cod-gray-900
    bg-opacity-80
    relative
    z-[0]
    rounded-full  ;

    &--own{
      @apply rounded-full
      right-0
      bottom-0
      m-2
      absolute
      left-auto;
    }

    img{
      filter: invert(100%) brightness(100%) saturate(0%);
    }

  }
  &__review {
    @apply md:flex flex-wrap justify-between
  }

  &__review-item {
    @apply mb-2 p-5

    relative
    overflow-hidden
    rounded-lg
    bg-white dark:bg-cod-gray-900
    shadow dark:shadow-cod-gray-900
    ease-in-out duration-500 cursor-pointer
    md:w-[calc(50%_-_10px)]
    ;


  }

  &__review-image {
    @apply rounded-full bg-amber-500 h-[60px] ;
    object-fit: cover;

  }

  &__review-rating {
    @apply ml-2
    dark:text-silver-500
    text-silver-700
    font-semibold;
  }

  &__review-login {
    @apply text-[18px] font-bold   hover:text-amber-500 w-full;
  }

  &__review-likes-block {
    @apply px-3 py-1 h-8
    flex justify-between items-center
    rounded-full
    dark:border-cod-gray-700  border  ;
    width: max-content;
  }

  &__review-likes {
    @apply font-semibold text-[18px] ml-3;

  }

  &__review-comment {
    @apply
    mt-5
    font-normal dark:text-silver-500
    text-silver-700;
  }

  &__review-date {
    @apply text-sm font-bold
    mt-5
    dark:text-silver-600
    text-silver-700;

  }


  &__review-comment {
    overflow: hidden;
    max-height: 98px;
    transition: max-height 0.3s ease;

    &.expanded {
      max-height: none;

      + .read-more {
        z-index: 11;
        position: relative;
        display: block;
      }
    }

    &--full {
      max-height: none;
    }

  }




}

.dark .game {
  &__info-item,
  &__review-likes-block {
    svg g,
    svg path {
      stroke: #fff !important;

    }
  }
}

.games-list {
  &__item {
    @apply h-full
    h-fit
    md:min-h-[270px]
    relative
  }

  &__item-link {
    @apply h-full p-2
    h-fit
    rounded-lg
    block  relative overflow-hidden
    border border-cod-gray-100
    bg-white
    transition
    dark:bg-cod-gray-900
    dark:border-cod-gray-800
    dark:shadow-md
    hover:shadow-md
    hover:-mt-2
    hover:z-10

    hover:absolute
    hover:h-auto;
    transition-duration: 500ms;
  }

  .games-list,
  .tiny-slide {
    *:has( &__item-link) {
      position: relative;
    }
  }


  &__item-image {
    @apply w-full
    rounded-lg
    dark:shadow-cod-gray-700;
    //transition-all duration-500;
    object-fit: cover;
    max-height: 320px;
    aspect-ratio: 3/4;

  }

  &__item-name {
    @apply mt-[10px]
    block
    font-semibold
    hover:text-amber-500;
  }

  &__item-info {
    display: none;

  }
  &__category{
    @apply absolute
    top-3 left-3
    text-xs
    font-bold
    block
    mb-2 px-2 py-1
    text-silver-500
    rounded-full
    bg-opacity-80
    bg-cod-gray-900
  }

  &__slider {
    position: relative;
  }

  &__slider &__item-info {
    @apply
    bg-white
    dark:bg-cod-gray-900
    absolute
    w-full
    z-10
    ;
    transition: max-height 0.3s ease;
    bottom: 0;
    right: 0;
    padding: 0 10px 10px;
  }

  &__item-link:hover &__item-info {
    display: block;
  }
}

.support-language {
  @apply
    text-sm
  w-full;
  tr {
    @apply p-2
    border-b
    border-cod-gray-200
    dark:border-cod-gray-700;
  }
  th span{
    @apply py-1 px-2
    bg-cod-gray-200
    dark:bg-cod-gray-700
    text-center
    rounded
  }
  td, th{
    @apply py-3
    text-center
  }
}


.tiny-slide {
  position: relative;

  .games-list__item-image {
    min-height: 270px;
    object-fit: cover;
  }
}

#gameTab .active-tab {
  color: #f7bf00 !important;
  border-color: #f7bf00 !important;
}

.full-img__modal {
  @apply hidden fixed z-[1000] inset-0 overflow-auto bg-cod-gray-900 bg-opacity-80 items-center;
}

.full-img__modal-content {
  @apply mx-auto block w-4/5 max-w-3xl max-h-screen m-auto object-contain;
  max-height: calc(100vh - 100px);

}

.full-img__close {
  @apply absolute top-4 right-8 text-white text-4xl   cursor-pointer;
}

.full-img__close:hover,
.full-img__close:focus {
  @apply text-gray-400;
}

//@screen max-sm{
//  .full-img__modal-content {
//    @apply w-full;
//  }
//}